import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { IMenu, PORTAL_MENU_ENROLLEE, PORTAL_MENU_MP, PORTAL_MENU_TP } from '../layout.config';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UserStatusEnum, UserTypeIdEnum } from 'src/app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})

export class SubHeaderComponent {
  menuItems: IMenu[] = [];
  userDetail: any;
  UserTypeIdEnum!: UserTypeIdEnum;
  userStatusEnum: any;
  private userDataSubscription: Subscription | any;
  isScrollActive = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    // Check if the user has scrolled
    if (window.scrollY > 0) {
      this.isScrollActive = true;
    } else {
      this.isScrollActive = false;
    }
  }

  constructor(private readonly cd: ChangeDetectorRef, public sharedService: SharedService) {
    this.userStatusEnum = UserStatusEnum;
  }

  ngOnInit(): void {
    this.userDataSubscription = this.sharedService.getUserData().subscribe(userData => {
      this.userDetail = userData;
    });
    this.userDetail = this.sharedService.getUser();
    switch (this.userDetail?.user_type) {
      case UserTypeIdEnum.ENROLLEE:
        this.menuItems = PORTAL_MENU_ENROLLEE;
        break;
      case UserTypeIdEnum.MEDICAL_PROVIDER:
        this.menuItems = PORTAL_MENU_MP;
        break;
      case UserTypeIdEnum.TRANSPORTATION_PROVIDER:
        this.menuItems = PORTAL_MENU_TP;
        break;
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.userDataSubscription.unsubscribe();
  }

}