import { IListHeaderConfig } from "src/app/core/interface/list-header.interface";
import { GenderEnum, UserTypeIdEnum } from "src/app/shared/interfaces/user.interface";

export const userHeaderConfig: IListHeaderConfig = {
    title: "Manage Users",
    search: {
        isSearch: true,
        paceholder: "Search"
    },
    export: {
        isExport: true,
        text: "Export CSV",
        type: 0,
        subBtns: []
    },
    add: {
        isAdd: true,
        btnText: "+  Add Account",
        type: 1,
        subBtns: [
            { label: 'Driver', user_type: 5, user_sub_type: 11 },
            { label: 'Vehicle', user_type: null, user_sub_type: null },
        ]
    },
    columns: {
        colLeftClass: "md:col-3",
        colRightClass: "md:col-9"
    }
}

const userSubTabs: IUserTabs[] = [
    { title: 'All', slug: 'all', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false },
    { title: 'New', slug: 'new', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false },
    { title: 'Verified', slug: 'verified', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false },
    { title: 'Activated', slug: 'activated', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false },
    { title: 'Deactivated', slug: 'deactivated', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false },
    { title: 'Deleted', slug: 'deleted', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false },
    { title: 'Blocked', slug: 'blocked', user_sub_type: UserTypeIdEnum.DRIVER, disabled: false },
]

export const userTabs: IUserTabs[] = [
    { title: 'Enrollees', slug: 'enrollee', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false, children: userSubTabs },
    { title: 'Driver', slug: 'driver', user_sub_type: UserTypeIdEnum.DRIVER, disabled: false, children: userSubTabs },
    { title: 'Vehicle', slug: 'vehicle', user_sub_type: UserTypeIdEnum.ENROLLEE, disabled: false, children: userSubTabs.filter(tab => tab.title !== 'Blocked') },
]

export interface IUserTabs {
    title: string,
    slug: string,
    user_sub_type: string,
    children?: any[],
    disabled: boolean
}

interface IFormHtml {
    formControlName: string,
    label: string,
    type: string,
    requiredMsg?: string,
    patternMsg?: string,
    placeholder: string,
    optionLabel?: string,
    optionValue?: string,
    formatMobile?: boolean,
    maxLength?: number,
    minLength?: number,
}

export const driverFormHtml: IFormHtml[] = [
    {
        formControlName: "first_name",
        label: "First Name",
        type: "text",
        requiredMsg: "*First Name is required",
        placeholder: "Enter First Name"
    },
    {
        formControlName: "last_name",
        type: "text",
        label: "Last Name",
        requiredMsg: "*Last name is required",
        placeholder: "Enter Last Name"
    },
    {
        formControlName: "email",
        type: "email",
        label: "Email",
        requiredMsg: "*Email is required",
        patternMsg: "Invalid Email",
        placeholder: "Enter Email"
    },
    {
        formControlName: "mobile",
        type: "inputMask",
        label: "Phone",
        requiredMsg: "*Phone no. is required",
        placeholder: "Enter mobile number",
        formatMobile: true
    },
    {
        formControlName: "date_of_birth",
        label: "Date of Birth",
        type: "calendar",
        requiredMsg: "*Date of Birth is required.",
        placeholder: "Enter Date of Birth.",
    },
    {
        formControlName: "vehicle_plate",
        type: "text",
        label: "Vehicle Plate",
        requiredMsg: "*Vehicle plate is required",
        placeholder: "Enter vehicle plate number"
    },
    {
        formControlName: "driver_licence",
        type: "text",
        label: "Driver License",
        requiredMsg: "*Driver License is required",
        placeholder: "Enter Driver License number"
    },
    {
        formControlName: "tlc_number",
        type: "text",
        label: "TLC License",
        requiredMsg: "*TLC License is required",
        placeholder: "Enter TLC License number"
    },
    {
        formControlName: "state_id",
        type: "dropdown",
        label: "State",
        requiredMsg: "*State is required",
        placeholder: "Select State",
        optionLabel: "state_name",
        optionValue: "id",
    },
    {
        formControlName: "zipcode",
        label: "Zipcode",
        type: "text",
        requiredMsg: "Zipcode is required",
        patternMsg: "Invalid Zipcode",
        placeholder: "Enter Zipcode",
        maxLength: 6,
    },
    {
        formControlName: "gender",
        type: "dropdown",
        label: "Gender",
        requiredMsg: "*Gender is required",
        placeholder: "Select Gender",
        optionLabel: "gender",
        optionValue: "id",
    },
];

export let formActions: any = {
    cancelBtn: {
        styleClass: "",
        label: "Cancel"
    },
    okbtn: {
        styleClass: "",
        label: " Account"
    },
    initailData: {
        states: [],
        gender: GenderEnum,
        carbase: [],
        mpList: [],
        drivers: [],
        isInitialDataLoaded: false
    }
}

