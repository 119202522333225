import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'prime-ride-access';
  envName!: string;

  constructor() {
    this.envName = environment.environmentLabel;
    console.log(environment);
  }
}
